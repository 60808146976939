import React from "react"
import styled from "styled-components"

const Circle = styled.button`
  height: 40px;
  width: 40px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`

const PauseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .pauseBar {
    height: 14px;
    width: 4px;
    margin: 0 2px;
    background-color: white;
  }
`

const Play = styled.div`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid white;
  transform: translateX(2px);
`

const Pause = () => (
  <PauseWrapper>
    <span className="pauseBar" />
    <span className="pauseBar" />
  </PauseWrapper>
)

const PlayButton = ({ className, isPlaying, togglePlayer }) => {
  return (
    <div className={className}>
      <Circle onClick={togglePlayer}>{isPlaying ? <Pause /> : <Play />}</Circle>
    </div>
  )
}

export default styled(PlayButton)``
