import React from "react"
import styled from "styled-components"
import PlayButton from "./PlayButton"
import washedAway from "../../music/washed_away.m4a"
import SongDescription from "./SongDescription"
import Scrubber from "./Scrubber"

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0;
  margin: 0;
  background-color: #222;
  display: flex;
  align-items: center;
`

const StyledPlayButton = styled(PlayButton)`
  margin: 0 20px;
`

class MusicPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.audio = React.createRef()
    this.timer = null
  }

  state = {
    isPlaying: false,
    loading: true,
    currentTime: 0,
  }

  componentDidMount() {
    this.checkForMediaLoad()
  }

  checkForMediaLoad = () => {
    if (this.audio.current.duration) {
      return this.setState({ loading: false })
    }

    setTimeout(() => this.checkForMediaLoad(), 1)
  }

  togglePlayer = () => {
    this.setState({ isPlaying: !this.state.isPlaying })
  }

  scrubTo = time => {
    this.audio.current.currentTime = time
    this.setState({ currentTime: time })
  }

  updatePlayer = () => {
    if (this.state.isPlaying) {
      this.audio.current.play()

      clearInterval(this.timer)

      this.timer = setInterval(() => {
        this.setState({ currentTime: this.audio.current.currentTime })
      }, 500)

      return
    }

    clearInterval(this.timer)
    this.audio.current.pause()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.isPlaying !== this.state.isPlaying ||
      prevState.currentTime !== this.state.currentTime
    ) {
      this.updatePlayer()
    }
  }

  render() {
    const { isPlaying, loading, currentTime } = this.state
    const { current } = this.audio
    const { mobile } = this.props

    return (
      <>
        {!loading && (
          <Wrapper>
            <StyledPlayButton
              togglePlayer={this.togglePlayer}
              isPlaying={isPlaying}
            />
            <SongDescription title="Washed Away" />
            <Scrubber
              currentTime={Math.floor(currentTime)}
              duration={current.duration}
              timer={this.timer}
              scrubTo={this.scrubTo}
              mobile={mobile}
            />
          </Wrapper>
        )}
        <audio ref={this.audio} src={washedAway} />
      </>
    )
  }
}

export default MusicPlayer
