import React from "react"
import styled from "styled-components"

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;

  span {
    height: 25px;
  }
`

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

const Artist = styled.span`
  font-size: 15px;
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: 13px;
  }
`

const SongDescription = ({ title, artist = "The Dapper Conspiracy" }) => (
  <Description>
    <Title>{title}</Title>
    <Artist>{artist}</Artist>
  </Description>
)

export default SongDescription
