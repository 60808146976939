import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100vw;
  height: 60px;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  background-color: #222;
  z-index: 100;
  border-bottom: none;
  transition: 100ms ease;

  @media only screen and (max-width: 600px) {
    justify-content: space-around;
  }

  a {
    padding: 0 20px;
    font-size: 20px;
    font-family: Avenir, Helvetica, sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;

    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
  }
`

const Header = ({ siteTitle }) => {
  return (
    <Wrapper>
      <Link to="/">home</Link>
      <Link to="/band">band</Link>
      <Link to="/videos">videos</Link>
      <Link to="/contact">contact</Link>
    </Wrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `The Dapper Conspiracy`,
}

export default Header
