import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  margin: 0 20px;

  p {
    margin: 0 20px;
    font-size: 15px;
  }
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 8px;

  input {
    -webkit-appearance: none;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #3f3f3f;
    transition: all 1s;
  }

  input:focus {
    outline: none;
  }

  input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-radius: 25px;
    z-index: 2;
  }

  input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
`

const Fill = styled.span`
  position: absolute;
  top: 0;
  height: 8px;
  width: ${({ percentage }) => `${percentage}%`};
  left: 0;
  background-color: #ffc66d;
  z-index: 1;
`

const secondsToMinSeconds = s => {
  const mins = Math.floor(s / 60)
  let seconds = Math.floor(s % 60)

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  return `${String(mins)}:${String(seconds)}`
}

const Scrubber = ({ duration, currentTime, timer, scrubTo, mobile }) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    setPercentage((currentTime / duration) * 100)
  }, [currentTime])

  return (
    <Wrapper>
      {!mobile && <p>{secondsToMinSeconds(currentTime)}</p>}
      <Slider>
        <input
          type="range"
          id="scrubber"
          name="scrubber"
          min="0"
          max={Math.floor(duration)}
          value={Math.floor(currentTime)}
          step="1"
          onChange={e => {
            clearInterval(timer)
            scrubTo(e.currentTarget.value)
          }}
        />
        <Fill percentage={percentage} />
      </Slider>

      {!mobile && <p>{secondsToMinSeconds(duration)}</p>}
    </Wrapper>
  )
}

export default Scrubber
