import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import MusicPlayer from "../components/MusicPlayer"
import "./layout.css"

const Wrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: 50px;
`

class Layout extends React.Component {
  state = {
    mobile: true,
  }

  checkMobile = () => {
    if (window.innerWidth < 800 && !this.state.mobile) {
      this.setState({ mobile: true })
    } else if (window.innerWidth > 800 && this.state.mobile) {
      this.setState({ mobile: false })
    }
  }

  componentDidMount() {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      this.checkMobile()
      window.addEventListener("resize", this.checkMobile)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile)
  }

  render() {
    const { children } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <Wrapper>
              <main>
                {React.Children.map(children, child =>
                  React.cloneElement(child, { mobile: this.state.mobile })
                )}
              </main>
            </Wrapper>
            <MusicPlayer mobile={this.state.mobile} />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
